<template>
  <div class="editAddress">
    <v-container class="register-form-group my-5 px-8 text-color">
      <v-row class="profile-form font-500 text-14 px-2">
        <v-col class="col-12">
          ที่อยู่ปัจจุบัน <span class="error--text">*</span>
          <ValidationProvider
            name="address"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mt-3 text-14"
              v-model="myAddress.address1"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกที่อยู่ปัจจุบัน"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          จังหวัด <span class="error--text">*</span>
          <ValidationProvider
            name="province"
            rules="required"
            v-slot="{ errors }"
          >
            <addressinput-province
              placeholder="โปรดกรอกจังหวัด"
              v-model="myAddress.province"
              label=""
            />
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          เขต / อำเภอ <span class="error--text">*</span>
          <ValidationProvider
            name="district"
            rules="required"
            v-slot="{ errors }"
          >
            <addressinput-district
              class="mt-1"
              v-model="myAddress.district"
              placeholder="โปรดกรอกเขต / อำเภอ"
              label=""
            />
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          แขวง / ตำบล <span class="error--text">*</span>
          <ValidationProvider
            name="sub_district"
            rules="required"
            v-slot="{ errors }"
          >
            <addressinput-subdistrict
              class="mt-1"
              v-model="myAddress.sub_district"
              placeholder="โปรดกรอกแขวง / ตำบล"
              label=""
            />
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          ถนน <span class="error--text">*</span>
          <ValidationProvider name="road" rules="required" v-slot="{ errors }">
            <v-text-field
              class="mt-3 text-14"
              v-model="myAddress.road"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกถนน"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          รหัสไปรษณีย์ <span class="error--text">*</span>
          <ValidationProvider
            name="postcode"
            :rules="{ required: true, regex: /^[0-9]*$/, max: 5, min: 5 }"
            v-slot="{ errors }"
          >
            <addressinput-zipcode
              class="mt-1"
              v-model="myAddress.postcode"
              placeholder="โปรดกรอกรหัสไปรษณีย์"
              label=""
            />
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>

        <v-col class="col-12 mt-5">
          <div class="text-center mb-5">
            <v-btn
              class="white--text primary--bg font-500 text-16 px-7 py-5"
              type="submit"
              rounded
              @click="submit()"
            >
              บันทึกการแก้ไข
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "edit-address",
  data() {
    return {
      myAddress: this.address,
    };
  },
  props: {
    address: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    submit() {
      this.$emit("submit", this.myAddress);
    },
  },
  created() {
    // console.log(this.myAddress);
  },
};
</script>
<style lang="scss" scoped>
.profile-form {
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  background: #fff;
}

.text-color {
  color: #757575;
}
</style>
