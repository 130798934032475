<template>
  <div class="editPolicy d-flex justify-center">
    <div class="Policy mt-2 mb-7">
      <v-card-text class="d-flex justify-center pa-5">
        <v-row>
          <v-col class="col-12 pt-0 mt-5 font-400">
            <v-checkbox
              v-model="term[findIndex('pdpa')].accepted"
              hide-details
              class="mt-0"
              :color="$vuetify.theme.themes['light'].primary"
            >
              <template v-slot:label>
                <span class="text-14">ยอมรับข้อกำหนดข้อมูลส่วนตัว PDPA</span>
              </template>
            </v-checkbox>
            <v-btn @click="readConsent('pdpa')" class="ml-4 mt-0" text>
              <span class="font-400 text-decoration-underline text-12"
                >อ่านรายละเอียด</span
              >
            </v-btn>
          </v-col>
          <v-col class="col-12 pt-0 font-400">
            <v-checkbox
              v-model="term[findIndex('marketing')].accepted"
              hide-details
              class="mt-0"
              :color="$vuetify.theme.themes['light'].primary"
            >
              <template v-slot:label>
                <span class="text-14"
                  >ยอมรับข้อตกลงและเงื่อนไขเกี่ยวกับการตลาด
                </span>
              </template>
            </v-checkbox>
            <v-btn
              @click="readConsent('marketing')"
              class="ml-4 mt-0 pt-0"
              text
            >
              <span class="font-400 text-decoration-underline text-12"
                >อ่านรายละเอียด</span
              >
            </v-btn>
          </v-col>
          <v-col class="col-12 pt-0 font-400">
            <v-checkbox
              v-model="term[findIndex('be')].accepted"
              hide-details
              class="mt-0"
              :color="$vuetify.theme.themes['light'].primary"
            >
              <template v-slot:label>
                <span class="text-14"
                  >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก Be Phyathai 3
                  Family</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="readConsent('be')" class="ml-4 mt-0" text>
              <span class="font-400 text-decoration-underline text-12"
                >อ่านรายละเอียด</span
              >
            </v-btn>
          </v-col>
          <v-col class="col-12 pt-0 font-400">
            <v-checkbox
              v-model="term[findIndex('prestige')].accepted"
              hide-details
              class="mt-0"
              :color="$vuetify.theme.themes['light'].primary"
            >
              <template v-slot:label>
                <span class="text-14"
                  >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก Long Term
                  Prestige</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="readConsent('prestige')" class="ml-4 mt-0" text>
              <span class="font-400 text-decoration-underline text-12"
                >อ่านรายละเอียด</span
              >
            </v-btn>
          </v-col>
          <v-col class="col-12 pt-0 font-400">
            <v-checkbox
              v-model="term[findIndex('all-you')].accepted"
              hide-details
              class="mt-0"
              :color="$vuetify.theme.themes['light'].primary"
            >
              <template v-slot:label>
                <span class="text-14"
                  >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก All You Can
                  Check</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="readConsent('all-you')" class="ml-4 mt-0" text>
              <span class="font-400 text-decoration-underline text-12"
                >อ่านรายละเอียด</span
              >
            </v-btn>
          </v-col>
          <v-col class="col-12 pt-0 font-400">
            <v-checkbox
              v-model="term[findIndex('mom-baby')].accepted"
              hide-details
              class="mt-0"
              :color="$vuetify.theme.themes['light'].primary"
            >
              <template v-slot:label>
                <span class="text-14"
                  >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก Mommy & Baby
                  Club</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="readConsent('mom-baby')" class="ml-4 mt-0" text>
              <span class="font-400 text-decoration-underline text-12"
                >อ่านรายละเอียด</span
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <div class="ma-10">
        <div class="text-center">
          <v-btn
            class="white--text primary--bg font-500 text-16 px-7 py-5"
            type="submit"
            rounded
            @click="submit()"
          >
            บันทึกการแก้ไข
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "editPolicy",
  data() {
    return {
      term: this.terms.consents,
    };
  },
  props: {
    terms: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    readConsent(id) {
      this.$emit("readTerm", id);
    },
    findIndex(id) {
      return this.term.findIndex((e) => e.id === id);
    },
    submit() {
      this.$emit("submit", this.term);
    },
  },
};
</script>
<style lang="scss" scoped>
.Policy {
  width: 353px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  background: #fff;
  color: #757575;
}
</style>
