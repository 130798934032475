<template>
  <div class="d-flex justify-start title-bar primary--text py-5 px-1 mb-3">
    <v-btn icon class="arrow-logo ma-2" @click="backPage">
      <img width="20px" height="12px" :src="arrowIcon" alt="arrow-icon" />
    </v-btn>
    <div class="d-flex align-center pl-3" v-if="shows === 'all'">
      <div class="text-20 font-700">ข้อมูลสมาชิกบัตร</div>
    </div>
    <div v-else-if="shows === 'information'">
      <div class="text-18 font-500">การแก้ไข</div>
      <div class="line-h-1 text-20 font-700">ข้อมูลทั่วไป</div>
    </div>
    <div v-else-if="shows === 'address'">
      <div class="text-18 font-500">การแก้ไข</div>
      <div class="line-h-1 text-20 font-700">ข้อมูลที่อยู่</div>
    </div>
    <div v-else-if="shows === 'isvisit'">
      <div class="text-18 font-500">การแก้ไข</div>
      <div class="line-h-1 text-20 font-700">ข้อมูลเพิ่มเติม</div>
    </div>
    <div v-else-if="shows === 'terms'">
      <div class="text-18 font-500">การแก้ไข</div>
      <div class="line-h-1 text-20 font-700">ข้อกำหนดและเงื่อนไข</div>
    </div>
    <div v-else-if="shows === 'consent'">
      <div class="text-18 font-500">เงื่อนไขและข้อตกลง</div>
      <div class="line-h-1 text-20 font-700">การใช้บัตรสมาชิก</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "registerTitle",
  data() {
    return {
      arrowIcon: require("@/assets/icon/arrowleft.png"),
    };
  },
  props: {
    onPage: {
      type: Number,
      default: 0,
    },
    shows: {
      type: String,
      default: "",
    },
  },
  methods: {
    backPage() {
      this.$emit("back");
    },
  },
};
</script>
<style lang="scss" scoped>
.title-bar {
  position: relative;
  border-bottom: 1px solid var(--v-primaryLight-base);
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
}
.icon {
  position: absolute;
  left: 72vw;
  bottom: 0;
}
.arrow-logo {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}
</style>
