<template>
  <div class="member-information">
    <div>
      <EditTitle :shows="show" @back="backPage" />
    </div>
    <div v-if="show === 'all'">
      <div class="d-flex justify-center">
        <div class="group">
          <!-- user display refferal -->
          <v-card class="user-referral card-box-border mt-10">
            <v-card-title class="primary-gradient--bg">
              <div
                class="user-display-group d-flex justify-center align-center"
              >
                <img :src="auth.profile.pictureUrl" class="user-display" />
              </div>
            </v-card-title>
            <v-card-text class="d-flex justify-center mt-10">
              <div class="primary--text text-18 font-500">
                Member ID : {{ user.code }}
              </div>
            </v-card-text>
          </v-card>

          <v-card class="user-information card-box-border mt-5">
            <v-card-title class="primary-gradient--bg">
              <div class="white--text font-700 text-20">ข้อมูลทั่วไป</div>
              <v-spacer />
              <div class="font-500 text-14" @click="swapToInformation">
                <v-icon color="white">mdi-file-document-edit-outline</v-icon>
                <span class="pl-1 white--text text-decoration-underline"
                  >แก้ไขข้อมูล</span
                >
              </div>
            </v-card-title>
            <v-card-text class="d-flex justify-center pa-5 pb-10">
              <v-row class="">
                <v-col class="col-5 pr-0">
                  <div>
                    <div>ชื่อ (Name)</div>
                    <div class="primary--text font-500 text-16 mt-1">
                      {{ user.general_profile.first_name }}
                    </div>
                  </div>
                </v-col>
                <v-col class="col-7 px-0">
                  <div>
                    <div>นามสกุล (Lastname)</div>
                    <div class="primary--text font-500 text-16 mt-1">
                      {{ user.general_profile.last_name }}
                    </div>
                  </div>
                </v-col>
                <v-col class="col-5 pr-0">
                  <div>
                    <div>เพศ (Sex)</div>
                    <div class="primary--text font-500 text-16 mt-1">
                      {{
                        user.general_profile.gender === "male"
                          ? "ชาย"
                          : "หญิง"
                      }}
                    </div>
                  </div>
                </v-col>
                <v-col class="col-7 px-0">
                  <div>
                    <div>วัน / เดือน / ปีเกิด (Brithday)</div>
                    <div class="primary--text font-500 text-16 mt-1">
                      {{ toDateDOBth(user.general_profile.birth_date) }}
                    </div>
                  </div>
                </v-col>
                <v-col class="col-12">
                  <div>เบอร์โทรศัพท์ (Phone Number)</div>
                  <div class="primary--text font-500 text-16 mt-1">
                    {{ user.general_profile.mobile }}
                  </div>
                </v-col>
                <v-col class="col-12">
                  <div>อีเมล (Email)</div>
                  <div class="primary--text font-500 text-16 mt-1">
                    {{ user.general_profile.email }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="user-information card-box-border mt-5">
            <v-card-title class="primary-gradient--bg">
              <div class="white--text font-700 text-20">ข้อมูลที่อยู่</div>
              <v-spacer />
              <div class="font-500 text-14" @click="swapToAddress">
                <v-icon color="white">mdi-file-document-edit-outline</v-icon>
                <span class="pl-1 white--text text-decoration-underline"
                  >แก้ไขข้อมูล</span
                >
              </div>
            </v-card-title>
            <v-card-text class="d-flex justify-center pa-5 pb-10">
              <div>
                <div>ที่อยู่ (Address)</div>
                <div class="primary--text font-500 text-16">
                  {{ address }}
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="user-information card-box-border mt-5">
            <v-card-title class="primary-gradient--bg">
              <div class="white--text font-700 text-20">ข้อมูลโรงพยาบาล</div>
              <v-spacer />
              <div class="font-500 text-14" @click="swapIsVisitPT">
                <v-icon color="white">mdi-file-document-edit-outline</v-icon>
                <span class="pl-1 white--text text-decoration-underline"
                  >แก้ไขข้อมูล</span
                >
              </div>
            </v-card-title>
            <v-card-text class="d-flex justify-center pa-5 pb-10">
              <div>
                <div>
                  ภายใน 5 ปี ที่ผ่านมา คุณเคยเข้ารับการรักษา หรือ
                  เข้ารับบริการที่ รพ. พญาไท 3 หรือไม่ ?
                </div>
                <v-radio-group
                  row
                  disabled
                  v-model="profile.hospital_profile.previous_pyathai_customer"
                  hide-details="auto"
                  class="py-0 my-0 mt-5"
                  mandatory
                >
                  <v-radio
                    class="col-6 pa-0 ma-0"
                    label="เคย"
                    color="#73C088"
                    :value="true"
                    hide-details="auto"
                    disabled
                    large
                  ></v-radio>
                  <v-radio
                    class="col-6 pa-0 ma-0"
                    label="ไม่เคย"
                    color="#73C088"
                    :value="false"
                    hide-details="auto"
                    disabled
                    large
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="user-information card-box-border my-5">
            <v-card-title class="primary-gradient--bg">
              <div class="white--text font-700 text-20">
                ข้อกำหนดและเงื่อนไข
              </div>
              <v-spacer />
              <div class="font-500 text-14" @click="swapTerms">
                <v-icon color="white">mdi-file-document-edit-outline</v-icon>
                <span class="pl-1 white--text text-decoration-underline"
                  >แก้ไขข้อมูล</span
                >
              </div>
            </v-card-title>
            <v-card-text class="d-flex justify-center pa-5 pb-10">
              <v-row>
                <v-col class="col-12 pt-0 mt-5 font-400">
                  <v-checkbox
                    :input-value="findConsent('pdpa')"
                    hide-details
                    disabled
                    class="mt-0"
                    color="#73C088"
                  >
                    <template v-slot:label>
                      <span class="text-14"
                        >ยอมรับข้อกำหนดข้อมูลส่วนตัว PDPA</span
                      >
                    </template>
                  </v-checkbox>
                  <v-btn
                    @click="readConsent('pdpa')"
                    class="ml-4 mt-0 pt-0"
                    text
                  >
                    <span class="font-400 text-decoration-underline text-12"
                      >อ่านรายละเอียด</span
                    >
                  </v-btn>
                </v-col>
                <v-col class="col-12 pt-0 font-400">
                  <v-checkbox
                    :input-value="findConsent('marketing')"
                    hide-details
                    disabled
                    class="mt-0"
                    color="#73C088"
                  >
                    <template v-slot:label>
                      <span class="text-14"
                        >ยอมรับข้อตกลงและเงื่อนไขเกี่ยวกับการตลาด
                      </span>
                    </template>
                  </v-checkbox>
                  <v-btn
                    @click="readConsent('marketing')"
                    class="ml-4 mt-0"
                    text
                  >
                    <span class="font-400 text-decoration-underline text-12"
                      >อ่านรายละเอียด</span
                    >
                  </v-btn>
                </v-col>
                <v-col class="col-12 pt-0 font-400">
                  <v-checkbox
                    :input-value="findConsent('be')"
                    hide-details
                    disabled
                    class="mt-0"
                    color="#73C088"
                  >
                    <template v-slot:label>
                      <span class="text-14"
                        >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก Be Phyathai 3
                        Family</span
                      >
                    </template>
                  </v-checkbox>
                  <v-btn @click="readConsent('be')" class="ml-4 mt-0" text>
                    <span class="font-400 text-decoration-underline text-12"
                      >อ่านรายละเอียด</span
                    >
                  </v-btn>
                </v-col>
                <v-col class="col-12 pt-0 font-400">
                  <v-checkbox
                    :input-value="findConsent('prestige')"
                    hide-details
                    disabled
                    class="mt-0"
                    color="#73C088"
                  >
                    <template v-slot:label>
                      <span class="text-14"
                        >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก Long Term
                        Prestige</span
                      >
                    </template>
                  </v-checkbox>
                  <v-btn
                    @click="readConsent('prestige')"
                    class="ml-4 mt-0"
                    text
                  >
                    <span class="font-400 text-decoration-underline text-12"
                      >อ่านรายละเอียด</span
                    >
                  </v-btn>
                </v-col>
                <v-col class="col-12 pt-0 font-400">
                  <v-checkbox
                    :input-value="findConsent('all-you')"
                    hide-details
                    disabled
                    class="mt-0"
                    color="#73C088"
                  >
                    <template v-slot:label>
                      <span class="text-14"
                        >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก All You Can
                        Check</span
                      >
                    </template>
                  </v-checkbox>
                  <v-btn @click="readConsent('all-you')" class="ml-4 mt-0" text>
                    <span class="font-400 text-decoration-underline text-12"
                      >อ่านรายละเอียด</span
                    >
                  </v-btn>
                </v-col>
                <v-col class="col-12 pt-0 font-400">
                  <v-checkbox
                    :input-value="findConsent('mom-baby')"
                    hide-details
                    disabled
                    class="mt-0"
                    color="#73C088"
                  >
                    <template v-slot:label>
                      <span class="text-14"
                        >ยอมรับข้อกำหนดเงื่อนไขการใช้งานบัตรสมาชิก Mommy & Baby
                        Club</span
                      >
                    </template>
                  </v-checkbox>
                  <v-btn
                    @click="readConsent('mom-baby')"
                    class="ml-4 mt-0"
                    text
                  >
                    <span class="font-400 text-decoration-underline text-12"
                      >อ่านรายละเอียด</span
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div v-if="show === 'information'">
      <ValidationObserver ref="generalProfileForm">
        <EditProfile
          :profiles="profile.general_profile"
          @submit="updateProfile"
        />
      </ValidationObserver>
    </div>
    <div v-if="show === 'address'">
      <ValidationObserver ref="addressProfileForm">
        <EditAddress :address="user.address_profile" @submit="updateAddress" />
      </ValidationObserver>
    </div>
    <div v-if="show === 'isvisit'">
      <EditIsVisitPT
        :isVisitPT="user.hospital_profile.previous_pyathai_customer"
        @submit="updateIsVisitPT"
      />
    </div>
    <div v-if="show === 'terms'">
      <EditTerms
        @readTerm="readTermDialog"
        :terms="user.consent_profile"
        @submit="updateTerms"
      />
    </div>
    <OTPForm
      v-if="show === 'OTP'"
      :isInvalidOTP="otpStatus.isInvalidOTP"
      :loading="otpStatus.loading"
      :refCode="otpStatus.refCode"
      @onSubmit="checkOTP"
      @onResend="sendOTP"
    />
    <TermsConsent v-if="show === 'consent'" :message="consent" />
    <SuccessModal
      :active="isSuccess"
      :message="successMessage"
      @submit="isSuccess = false"
    />
    <TermDialog
      :active="showTermDialog"
      :terms="consent"
      @close="showTermDialog = false"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import EditTitle from "../components/profile/EditTitle";
import EditProfile from "@/components/profile/EditProfile";
import EditAddress from "@/components/profile/EditAddress";
import EditIsVisitPT from "@/components/profile/EditIsVisitPT";
import EditTerms from "@/components/profile/EditTerms";
import OTPForm from "@/components/profile/OTPForm";
import TermsConsent from "../components/TermsConsent.vue";
import SuccessModal from "../components/modal/SuccessModal.vue";
import TermDialog from "../components/dialog/TermDialog.vue";

export default {
  name: "MemberInformation",
  components: {
    EditTitle,
    EditProfile,
    EditAddress,
    EditIsVisitPT,
    EditTerms,
    OTPForm,
    TermsConsent,
    SuccessModal,
    TermDialog,
  },
  data() {
    return {
      show: "all",
      profile: [],
      userProfileEdit: null,
      otpStatus: {
        isInvalidOTP: false,
        loading: false,
        refCode: null,
      },
      showTermDialog: false,
      isSuccess: false,
      successMessage: {
        title: "บันทึกข้อมูลเรียบร้อย",
        description: "ระบบได้ทำการบันทึกข้อมูลของท่านแล้ว",
      },
      consent: {},
      userDisplay: require("@/assets/img/mockDisplay.png"),
    };
  },
  props: {},
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("Auth", ["auth"]),
    ...mapState("Request", ["requestApi"]),
    ...mapState("Layout", ["onDomain"]),

    address() {
      const address = this.profile.address_profile;
      return `${address.address1} ${address.road} ${address.district} ${address.sub_district} ${address.province} ${address.postcode}`;
    },
  },
  methods: {
    ...mapActions({
      setonDomain: "Layout/setonDomain",
    }),
    checkOTP() {},
    sendOTP() {},
    async readTermDialog(id) {
      try {
        this.$wait.start("app loading");
        const consents = await this.requestApi.consent.getConsent({
          id,
        });
        this.consent = {
          consents,
        };
        this.showTermDialog = true;
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    findConsent(id) {
      return this.profile.consent_profile.consents.filter((e) => e.id === id)[0]
        .accepted;
    },
    async getUserMember() {
      try {
        this.$wait.start("app loading");
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async readConsent(value) {
      try {
        this.$wait.start("app loading");
        const consents = await this.requestApi.consent.getConsent({
          id: value,
        });
        this.show = "consent";
        this.consent = {
          consents,
        };
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },

    swapToInformation() {
      this.show = "information";
    },
    swapToAddress() {
      this.show = "address";
    },
    swapIsVisitPT() {
      this.show = "isvisit";
    },
    swapTerms() {
      this.show = "terms";
    },
    editSuccess() {
      this.show = "all";
    },

    backPage() {
      if (this.onDomain !== "be") {
        const index = this.user.memberships.findIndex(
          (m) => m.domain === this.onDomain
        );
        const member = this.user.memberships[0];
        this.user.memberships[0] = this.user.memberships[index];
        this.user.memberships[index] = member;
      }
      if (this.show === "all") {
        this.$router.replace("/home");
      } else {
        this.show = "all";
      }
    },
    async updateProfile(profile) {
      const isValid = await this.$refs.generalProfileForm.validate();
      if (isValid) {
        try {
          const general_profile = {
            birth_date: profile.birth_date,
            first_name: profile.first_name,
            gender: profile.gender,
            last_name: profile.last_name,
            mobile: profile.mobile,
          };
          profile.email ? (general_profile["email"] = profile.email) : false;

          this.$wait.start("app loading");
          await this.requestApi.user.updateUser({
            requestBody: { general_profile },
          });
          this.isSuccess = true;
        } catch (err) {
          console.log(err);
          // alert(err);
        } finally {
          this.$wait.end("app loading");
        }
      }
    },
    async updateAddress(address) {
      const isValid = await this.$refs.addressProfileForm.validate();
      if (isValid) {
        try {
          this.$wait.start("app loading");
          await this.requestApi.user.updateUser({
            requestBody: {
              address_profile: address,
            },
          });
          this.isSuccess = true;
        } catch (err) {
          console.log(err);
          alert(err);
        } finally {
          this.$wait.end("app loading");
        }
      }
    },
    async updateIsVisitPT(isVisit) {
      try {
        this.$wait.start("app loading");

        await this.requestApi.user.updateUser({
          requestBody: {
            hospital_profile: {
              previous_pyathai_customer: isVisit,
            },
          },
        });
        this.isSuccess = true;
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async updateTerms(terms) {
      try {
        this.$wait.start("app loading");
        let consent_profile = {};
        const getContent = async (consent) => {
          const term = await this.requestApi.consent.getConsent({
            id: consent.id,
          });
          return {
            id: consent.id,
            name: consent.id,
            content: term.content,
            accepted: Boolean(consent.accepted),
          };
        };
        const consentPromises = terms.map((consent) => getContent(consent));
        await Promise.allSettled(consentPromises).then((result) => {
          consent_profile = { consents: result.map((e) => e.value) };
        });
        await this.requestApi.user.updateUser({
          requestBody: { consent_profile },
        });
        this.isSuccess = true;
      } catch (err) {
        alert(err);
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
  },
  created() {
    this.profile = JSON.parse(JSON.stringify(this.user))
    // console.log(this.user.general_profile.first_name);
  },
};
</script>
<style lang="scss" scoped>
.card-box-border {
  position: relative;
  width: 350px;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}

.user-display {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 2px solid var(--v-primary-base);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}

.user-display-group {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: #f2f2f2;
  border: 2px solid var(--v-primary-base);
  border-radius: 100%;
  width: 90px;
  height: 90px;
}
</style>
