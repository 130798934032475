<template>
  <div class="editAddress d-flex justify-center">
    <div class="register-form-group mt-2">
      <div class="text-14 pa-5 pr-6">
        <div class="font-500">
          <div>
            ภายใน 5 ปี ที่ผ่านมา คุณเคยเข้ารับการรักษา หรือ เข้ารับบริการที่
            รพ.พญาไท 3 หรือไม่ ? <span class="error--text">*</span>
          </div>
          <v-radio-group
            v-model="isVisit"
            row
            class="py-0 my-5 mt-3"
            hide-details
            mandatory
          >
            <v-radio
              class="col-6 pl-0"
              label="เคย"
              :color="$vuetify.theme.themes['light'].primary"
              :value="true"
              hide-details
              large
            ></v-radio>
            <v-radio
              label="ไม่เคย"
              :color="$vuetify.theme.themes['light'].primary"
              :value="false"
              hide-details
              large
            ></v-radio>
          </v-radio-group>
        </div>
        <v-divider></v-divider>
        <div class="mt-5 mb-5">
          <div class="text-center">
            <v-btn
              class="primary--bg white--text font-500 text-16 px-7 py-5"
              type="submit"
              rounded
              @click="submit()"
            >
              บันทึกการแก้ไข
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "editEditIsVisitPT",
  data() {
    return {
      isVisit: this.isVisitPT,
    };
  },
  props: {
    isVisitPT: Boolean,
    default: () => false,
  },
  methods: {
    submit() {
      this.$emit("submit", this.isVisit);
    },
  },
};
</script>
<style lang="scss" scoped>
.register-form-group {
  width: 353px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  background: #fff;
  color: #757575;
}
</style>
