<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent>
      <v-card class="card">
        <v-card-title
          class="bg-color primary-gradient--bg d-flex justify-space-around pa-3"
        >
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn class="bg-icon" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="d-flex justify-center">
          <div class="card-container px-5">
            <div class="terms-consent-title pt-5">
              <div class="terms-consent-head primary--text">
                <div class="text-18 font-500">
                  เงื่อนไขและข้อตกลงการใช้บัตรสมาชิก
                </div>
                <div class="line-h-1 text-20 font-700">{{ titleByDomain }}</div>
              </div>
            </div>
            <div class="card-group pb-5 px-2">
              <div class="terms-consent-detail">
                <div class="terms-consent-body">
                  <div
                    v-html="term?.content"
                    style="overflow-wrap: break-word"
                    class="privilege text-12 text-wrap font-500 py-5"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "registerwithcodemodal",
  data() {
    return {
      closeicon: require("@/assets/icon/closeicon.png"),
      arrowLeft: require("@/assets/icon/arrowleft.png"),
      term: {},
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    terms: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    terms() {
      this.setConsent();
    },
  },
  computed: {
    titleByDomain() {
      if (this.term.id === "be") {
        return "Be Phyathai 3 Family";
      } else if (this.term.id === "prestige") {
        return "Long Terms Prestige";
      } else if (this.term.id === "all-you") {
        return "All You Can Check";
      } else if (this.term.id === "pdpa") {
        return "PDPA";
      } else if (this.term.id === "marketing") {
        return "Marketing";
      } else {
        return "Mommy & Baby Club";
      }
    },
  },
  methods: {
    setConsent() {
      this.term = this.terms.consents;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.message {
  color: #235d3a;
}
.bg-color {
  color: #ffffff;
}
.bg-icon {
  background: #fff;
}

.card-img {
  object-fit: cover;
  border-radius: 20px;
  width: 300px;
  height: 185px;
}
</style>
