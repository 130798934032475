<template>
  <div class="editprofile">
    <v-container class="register-form-group my-5 px-8 text-color">
      <v-row class="profile-form font-500 text-14 px-2">
        <v-col class="col-12">
          <div>ชื่อจริง <span class="error--text">*</span></div>
          <ValidationProvider
            name="firstName"
            :rules="{ required: true, regex: /^[a-zA-Zก-๙]*$/ }"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.first_name"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกชื่อจริง"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          นามสกุล <span class="error--text">*</span>
          <ValidationProvider
            name="lastName"
            :rules="{ required: true, regex: /^[a-zA-Zก-๙]*$/ }"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.last_name"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกชื่อนามสกุล"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          เพศ <span class="error--text">*</span>
          <ValidationProvider
            name="gender"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              class="mt-3 text-14"
              v-model="profile.gender"
              :items="genderOption"
              item-value="value"
              item-text="name"
              placeholder="กรุณาเลือกเพศ"
              data-vv-name="select"
              required
              rounded
              outlined
              dense
              hide-details="auto"
            ></v-select>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          วัน / เดือน / ปีเกิด <span class="error--text">*</span>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <ValidationProvider
                name="datebirthday"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="getDateFormat"
                  placeholder="ระบุวันเกิด"
                  readonly
                  outlined
                  rounded
                  dense
                  class="text-12"
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                <span class="error--text">{{ errors[0] }}</span>
              </ValidationProvider>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              :active-picker.sync="activePicker"
              :max="maxAge"
              :min="minAge"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="col-12">
          เบอร์โทรศัพท์ <span class="error--text">*</span>
          <ValidationProvider
            name="mobile"
            :rules="{ required: true, regex: /^[0-9]*$/, max: 10 }"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.mobile"
              outlined
              rounded
              dense
              required
              hide-details="auto"
              placeholder="โปรดกรอกเบอร์โทรศัพท์ Ex. 0891231234"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12">
          อีเมล
          <ValidationProvider name="email" rules="email" v-slot="{ errors }">
            <v-text-field
              class="mt-3 text-14"
              v-model="profile.email"
              outlined
              rounded
              dense
              hide-details="auto"
              placeholder="กรุณากรอกอีเมล Ex. abc@gmail.com"
            ></v-text-field>
            <span class="error--text">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
        <v-col class="col-12 mt-5">
          <div class="text-center mb-5">
            <v-btn
              class="white--text primary--bg font-500 text-16 px-7 py-5"
              type="submit"
              rounded
              @click="submit()"
            >
              บันทึกการแก้ไข
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "editprofile",
  data(vm) {
    return {
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      activePicker: null,
      date: null,
      profile: this.profiles,

      genderOption: ["male", "female"],
      menu: false,
    };
  },
  props: {
    profiles: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.profile.birth_date = this.toTimestamp(this.date);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    maxAge() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 0);
      return `${new Date(date).toISOString().split("-")[0]}-12-31`;
    },
    minAge() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 90);
      return `${new Date(date).toISOString().split("-")[0]}-0-0`;
    },
    getDateFormat() {
      return this.formatDate(this.profile.birth_date);
    },
  },
  methods: {
    formatDate(date) {
      const [year, month, day] = this.toDateDOB(date).split("-");
      return `${month}/${day}/${year}`;
    },
    submit() {
      this.$emit("submit", this.profile);
    },
  },
};
</script>
<style lang="scss" scoped>
.user-form {
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  background: #fff;
}

.text-color {
  color: #757575;
}
</style>
